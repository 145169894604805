import 'magnific-popup';
import moment from 'moment';

jQuery( document ).ready(function($) {
    // $.magnificPopup.open( {
    //     items: {
    //         src: '#presave',
    //         type: 'inline'
    //     }
    // } );

    $('.internal').on('click', function(e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        $('#page-wrap').removeClass();
        $('#page-wrap').addClass(TARGET.substring(1));
        $('#main section').hide();
        $(TARGET).show();
		history.pushState({state:1}, "BROCKHAMPTON", TARGET);
    });

	$(window).on('popstate', function() {
		location.reload(true);
	});

	$('.credit-toggle a').on('click', function(e){
        const TARGET = $(this).attr('href');
		e.preventDefault();
		$('#credits .active').removeClass('active');
		$(TARGET).addClass('active');
		$(this).addClass('active');
	})

	// BIT
    $.ajax( {
		url: 'https://rest.bandsintown.com/artists/Brockhampton/events?app_id=45PRESS_bh',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			console.log(data);
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let region = event.venue.region?event.venue.region:event.venue.country
					let location = event.venue.city + ', ' + region;
					if ( location === ', ' ) {
						location = '';
					}
					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}
					html += '<div class="event">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D YYYY' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					// html += '<div class="event-links">';
					// if (event.offers.length) {
					// 	for ( let offer of event.offers ) {
					// 		html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
					// 	}
					// } else {
					// 	html += '<a href="' + event.url + '" target="_blank" class="btn btn">More Info</a>';
					// }
					// html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( '<div class="event no-event">No upcoming events.</div>' );
			}
			$('.seealllink').on('click', function(e){
				e.preventDefault();
				$('#events').addClass('seeall');
			})
		}
	} );

	if(window.location.hash) {
        const TARGET2 = window.location.hash;
		console.log(TARGET2)
        $('#page-wrap').removeClass();
        $('#page-wrap').addClass(TARGET2.substring(1)); 
        $('#main section').hide();
        $(TARGET2).show(); 
    }

    //presaves
	//Apple Form
	// $('body').on('click','.btn-apple', function( e ) { 
	// 	e.preventDefault();
	// 	$.magnificPopup.open( {
	// 		items: {
	// 			src: '#apple-popup',
	// 			type: 'inline'
	// 		}
	// 	} );
	// 	var sm = new SMEAppleMusic({
	// 		ae: {
	// 		  ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
	// 		  brand_id: "3453920",
	// 		  segment_id: "1727605",
	// 		  activities: '{"actions":{"presave":100244},"mailing_list_optins":{"a0S1p00000Ri9LgEAJ":100245}}'
	// 		},
	// 		am: {
	// 		  dev_token: "",
	// 		  save_mode: "library",
	// 		  custom_playlist_name: "",
	// 		  new_apple_presave: true,
	// 		  resources: {
	// 			playlists: [],
	// 			albums: []
	// 		  }
	// 		},
	// 		sf: {
	// 		  form: "228441",
	// 		  default_mailing_list: ""
	// 		},
	// 		smf: {
	// 		  campaign_id: "228441",
	// 		  campaign_key: "90f73efd9696e1b4ddfa515d4ca3cb07"
	// 		}
	// 	  });
	// 	  document.getElementById("apple_music_form").addEventListener("submit", function (e) {
	// 		e.preventDefault();
	// 		// Grab the email address value.
	// 		var email = document.getElementById("apple_music_email_address").value;
	// 		// Pass the email address and opt in status
	// 		// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
	// 		sm.doActions(email, false, ["a0S1p00000Ri9LgEAJ"]).then(function (res) {	
	// 			$.magnificPopup.open( {
	// 				items: {
	// 					src: '#thanks',
	// 					type: 'inline'
	// 				}
	// 			} );
	// 		}).catch(function(err) {
	// 			console.log(err);
	// 		});
	// 	});
	// } );
	// //Spotify / Deezer
	// let SMEPreSave = {
	// 	state: null,
	// 	spotify_user: null,
	// 	url_vars: [],
	// 	getURLVars: function (callback, form_id) {
	// 	let hash;
	// 	let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	// 	for (let i = 0; i < hashes.length; i++) {
	// 		hash = hashes[i].split('=');
	// 		this.url_vars.push(hash[0]);
	// 		this.url_vars[hash[0]] = hash[1];
	// 		}
	// 	this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
	// 	this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
	// 	this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
	// 	this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
	// 	this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
	// 	if (typeof callback === 'function') {
	// 		callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
	// 	}
	// 	}
	// };
	// /* String state
	// 	*  The status of the pre-save.
	// 	*  Int ae_member_id
	// 	*  The Appreciation Engine member ID. This can be passed to the forms processor.
	// 	*  String spotify_user
	// 	*  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
	// 	*  String deezery_user
	// 	*  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
	// 	*/
	// SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
	// 	// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
	// 	if (state === 'success' || state === 'thank-you') {
	// 		$.magnificPopup.open( {
	// 			items: {
	// 				src: '#thanks',
	// 				type: 'inline'
	// 			}
	// 		} );
	// 	}
	// });
});